// Code type map
$code-type-map: (
  // Chroma supported languages
  // @generator https://github.com/hugo-fixit/.tools/blob/main/tampermonkey/code-type-map-fetcher.user.js
  "abap": "ABAP",
  "abnf": "ABNF",
  "as": "ActionScript",
  "actionscript": "ActionScript",
  "as3": "ActionScript 3",
  "actionscript3": "ActionScript 3",
  "ada": "Ada",
  "ada95": "Ada",
  "ada2005": "Ada",
  "al": "AL",
  "ng2": "Angular2",
  "antlr": "ANTLR",
  "apacheconf": "ApacheConf",
  "aconf": "ApacheConf",
  "apache": "ApacheConf",
  "apl": "APL",
  "applescript": "AppleScript",
  "arduino": "Arduino",
  "armasm": "ArmAsm",
  "awk": "Awk",
  "gawk": "Awk",
  "mawk": "Awk",
  "nawk": "Awk",
  "ballerina": "Ballerina",
  "make": "Base Makefile",
  "makefile": "Base Makefile",
  "mf": "Base Makefile",
  "bsdmake": "Base Makefile",
  "bash": "Bash",
  "sh": "Bash",
  "ksh": "Bash",
  "zsh": "Bash",
  "shell": "Bash",
  "bash-session": "BashSession",
  "console": "BashSession",
  "shell-session": "BashSession",
  "bat": "Batchfile",
  "batch": "Batchfile",
  "dosbatch": "Batchfile",
  "winbatch": "Batchfile",
  "bib": "BibTeX",
  "bibtex": "BibTeX",
  "bicep": "Bicep",
  "blitzbasic": "BlitzBasic",
  "b3d": "BlitzBasic",
  "bplus": "BlitzBasic",
  "bnf": "BNF",
  "brainfuck": "Brainfuck",
  "bf": "Brainfuck",
  "c": "C",
  "csharp": "C#",
  "c\\#": "C#",
  "cpp": "C++",
  "c\\+\\+": "C++",
  "caddyfile": "Caddyfile",
  "caddy": "Caddyfile",
  "caddyfile-directives": "Caddyfile Directives",
  "caddyfile-d": "Caddyfile Directives",
  "caddy-d": "Caddyfile Directives",
  "capnp": "Cap'n Proto",
  "cassandra": "Cassandra CQL",
  "cql": "Cassandra CQL",
  "ceylon": "Ceylon",
  "cfengine3": "CFEngine3",
  "cf3": "CFEngine3",
  "cfs": "cfstatement",
  "chai": "ChaiScript",
  "chaiscript": "ChaiScript",
  "cheetah": "Cheetah",
  "spitfire": "Cheetah",
  "clojure": "Clojure",
  "clj": "Clojure",
  "cmake": "CMake",
  "cobol": "COBOL",
  "coffee-script": "CoffeeScript",
  "coffeescript": "CoffeeScript",
  "coffee": "CoffeeScript",
  "common-lisp": "Common Lisp",
  "cl": "Common Lisp",
  "lisp": "Common Lisp",
  "coq": "Coq",
  "cr": "Crystal",
  "crystal": "Crystal",
  "css": "CSS",
  "cython": "Cython",
  "pyx": "Cython",
  "pyrex": "Cython",
  "d": "D",
  "dart": "Dart",
  "diff": "Diff",
  "udiff": "Diff",
  "django": "Django/Jinja",
  "jinja": "Django/Jinja",
  "docker": "Docker",
  "dockerfile": "Docker",
  "dtd": "DTD",
  "dylan": "Dylan",
  "ebnf": "EBNF",
  "elixir": "Elixir",
  "ex": "Elixir",
  "exs": "Elixir",
  "elm": "Elm",
  "emacs": "EmacsLisp",
  "elisp": "EmacsLisp",
  "emacs-lisp": "EmacsLisp",
  "erlang": "Erlang",
  "factor": "Factor",
  "fennel": "Fennel",
  "fnl": "Fennel",
  "fish": "Fish",
  "fishshell": "Fish",
  "forth": "Forth",
  "fortran": "Fortran",
  "fortranfixed": "FortranFixed",
  "fsharp": "FSharp",
  "gas": "GAS",
  "asm": "GAS",
  "gdscript": "GDScript",
  "gd": "GDScript",
  "genshi": "Genshi",
  "kid": "Genshi",
  "xml\\+genshi": "Genshi",
  "xml\\+kid": "Genshi",
  "html\\+genshi": "Genshi HTML",
  "html\\+kid": "Genshi HTML",
  "genshitext": "Genshi Text",
  "cucumber": "Gherkin",
  "Cucumber": "Gherkin",
  "gherkin": "Gherkin",
  "Gherkin": "Gherkin",
  "glsl": "GLSL",
  "gnuplot": "Gnuplot",
  "go": "Go",
  "golang": "Go",
  "go-html-template": "Go HTML Template",
  "go-text-template": "Go Text Template",
  "graphql": "GraphQL",
  "graphqls": "GraphQL",
  "gql": "GraphQL",
  "groff": "Groff",
  "nroff": "Groff",
  "man": "Groff",
  "groovy": "Groovy",
  "handlebars": "Handlebars",
  "hbs": "Handlebars",
  "haskell": "Haskell",
  "hs": "Haskell",
  "hx": "Haxe",
  "haxe": "Haxe",
  "hxsl": "Haxe",
  "hcl": "HCL",
  "hexdump": "Hexdump",
  "hlb": "HLB",
  "html": "HTML",
  "http": "HTTP",
  "hylang": "Hy",
  "idris": "Idris",
  "idr": "Idris",
  "igor": "Igor",
  "igorpro": "Igor",
  "ini": "INI",
  "cfg": "INI",
  "dosini": "INI",
  "io": "Io",
  "j": "J",
  "java": "Java",
  "js": "JavaScript",
  "javascript": "JavaScript",
  "json": "JSON",
  "julia": "Julia",
  "jl": "Julia",
  "jungle": "Jungle",
  "kotlin": "Kotlin",
  "lighty": "Lighttpd configuration file",
  "lighttpd": "Lighttpd configuration file",
  "llvm": "LLVM",
  "lua": "Lua",
  "mako": "Mako",
  "md": "Markdown",
  "mkd": "Markdown",
  "mason": "Mason",
  "mathematica": "Mathematica",
  "mma": "Mathematica",
  "nb": "Mathematica",
  "matlab": "Matlab",
  "mcfunction": "mcfunction",
  "meson": "Meson",
  "meson\\.build": "Meson",
  "metal": "Metal",
  "minizinc": "MiniZinc",
  "MZN": "MiniZinc",
  "mzn": "MiniZinc",
  "mlir": "MLIR",
  "modula2": "Modula-2",
  "m2": "Modula-2",
  "monkeyc": "MonkeyC",
  "morrowind": "MorrowindScript",
  "mwscript": "MorrowindScript",
  "myghty": "Myghty",
  "mysql": "MySQL",
  "mariadb": "MySQL",
  "nasm": "NASM",
  "newspeak": "Newspeak",
  "nginx": "Nginx configuration file",
  "nim": "Nim",
  "nimrod": "Nim",
  "nixos": "Nix",
  "nix": "Nix",
  "objective-c": "Objective-C",
  "objectivec": "Objective-C",
  "obj-c": "Objective-C",
  "objc": "Objective-C",
  "ocaml": "OCaml",
  "octave": "Octave",
  "ones": "OnesEnterprise",
  "onesenterprise": "OnesEnterprise",
  "1S": "OnesEnterprise",
  "1S:Enterprise": "OnesEnterprise",
  "openedge": "OpenEdge ABL",
  "abl": "OpenEdge ABL",
  "progress": "OpenEdge ABL",
  "openedgeabl": "OpenEdge ABL",
  "openscad": "OpenSCAD",
  "org": "Org Mode",
  "orgmode": "Org Mode",
  "pacmanconf": "PacmanConf",
  "perl": "Perl",
  "pl": "Perl",
  "php": "PHP",
  "php3": "PHP",
  "php4": "PHP",
  "php5": "PHP",
  "phtml": "PHTML",
  "pig": "Pig",
  "pkgconfig": "PkgConfig",
  "plpgsql": "PL/pgSQL",
  "text": "plaintext",
  "plain": "plaintext",
  "no-highlight": "plaintext",
  "plutus-core": "Plutus Core",
  "plc": "Plutus Core",
  "pony": "Pony",
  "postgresql": "PostgreSQL SQL dialect",
  "postgres": "PostgreSQL SQL dialect",
  "postscript": "PostScript",
  "postscr": "PostScript",
  "pov": "POVRay",
  "powerquery": "PowerQuery",
  "pq": "PowerQuery",
  "powershell": "PowerShell",
  "posh": "PowerShell",
  "ps1": "PowerShell",
  "psm1": "PowerShell",
  "psd1": "PowerShell",
  "prolog": "Prolog",
  "promql": "PromQL",
  "protobuf": "Protocol Buffer",
  "proto": "Protocol Buffer",
  "puppet": "Puppet",
  "python": "Python",
  "py": "Python",
  "sage": "Python",
  "python3": "Python",
  "py3": "Python",
  "python2": "Python 2",
  "py2": "Python 2",
  "qbasic": "QBasic",
  "basic": "QBasic",
  "qml": "QML",
  "qbs": "QML",
  "splus": "R",
  "s": "R",
  "r": "R",
  "racket": "Racket",
  "rkt": "Racket",
  "ragel": "Ragel",
  "perl6": "Raku",
  "pl6": "Raku",
  "raku": "Raku",
  "jsx": "react",
  "react": "react",
  "reason": "ReasonML",
  "reasonml": "ReasonML",
  "registry": "reg",
  "rst": "reStructuredText",
  "rest": "reStructuredText",
  "restructuredtext": "reStructuredText",
  "rexx": "Rexx",
  "arexx": "Rexx",
  "rb": "Ruby",
  "ruby": "Ruby",
  "duby": "Ruby",
  "rust": "Rust",
  "rs": "Rust",
  "sas": "SAS",
  "sass": "Sass",
  "scala": "Scala",
  "scheme": "Scheme",
  "scm": "Scheme",
  "scilab": "Scilab",
  "scss": "SCSS",
  "sieve": "Sieve",
  "smalltalk": "Smalltalk",
  "squeak": "Smalltalk",
  "st": "Smalltalk",
  "smarty": "Smarty",
  "snobol": "Snobol",
  "sol": "Solidity",
  "solidity": "Solidity",
  "sparql": "SPARQL",
  "sql": "SQL",
  "squidconf": "SquidConf",
  "squid\\.conf": "SquidConf",
  "squid": "SquidConf",
  "sml": "Standard ML",
  "stylus": "Stylus",
  "svelte": "Svelte",
  "swift": "Swift",
  "systemd": "SYSTEMD",
  "systemverilog": "systemverilog",
  "sv": "systemverilog",
  "tablegen": "TableGen",
  "tasm": "TASM",
  "tcl": "Tcl",
  "tcsh": "Tcsh",
  "csh": "Tcsh",
  "termcap": "Termcap",
  "terminfo": "Terminfo",
  "terraform": "Terraform",
  "tf": "Terraform",
  "tex": "TeX",
  "latex": "TeX",
  "thrift": "Thrift",
  "toml": "TOML",
  "tradingview": "TradingView",
  "tv": "TradingView",
  "tsql": "Transact-SQL",
  "t-sql": "Transact-SQL",
  "turing": "Turing",
  "turtle": "Turtle",
  "twig": "Twig",
  "ts": "TypeScript",
  "tsx": "TypeScript",
  "typescript": "TypeScript",
  "typoscript": "TypoScript",
  "typoscriptcssdata": "TypoScriptCssData",
  "typoscripthtmldata": "TypoScriptHtmlData",
  "v": "verilog",
  "vlang": "V",
  "vsh": "V shell",
  "vshell": "V shell",
  "vb\\.net": "VB.net",
  "vbnet": "VB.net",
  "verilog": "verilog",
  "vhdl": "VHDL",
  "vim": "VimL",
  "vue": "vue",
  "vuejs": "vue",
  "whiley": "Whiley",
  "xml": "XML",
  "xorg\\.conf": "Xorg",
  "yaml": "YAML",
  "yang": "YANG",
  "zed": "Zed",
  "zig": "Zig",
  // Custom code type
  "markdown": "Markdown",
  "yml": "YAML"
) !default;
