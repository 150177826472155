.mermaid {
  &[data-processed='true'] {
    text-align: center;
  }

  svg {
    max-width: 100%;
    height: auto; 
  }
}
