/**
 * Link mixin
 * @param {Boolean} $light use global link color
 * @param {Boolean} $dark use global link color dark
 * @param {String} $decoration text-decoration, default none
 */
@mixin link($light, $dark, $decoration: none) {
  a,
  a::before,
  a::after {
    text-decoration: $decoration;

    @if $light {
      color: $global-link-color;
    }

    @else {
      color: $single-link-color;
    }

    [data-theme='dark'] & {
      @if $dark {
        color: $global-link-color-dark;
      }

      @else {
        color: $single-link-color-dark;
      }
    }
  }

  a:active,
  a:hover {
    @if $light {
      color: $global-link-hover-color;
    }

    @else {
      color: $single-link-hover-color;
    }

    [data-theme='dark'] & {
      @if $dark {
        color: $global-link-hover-color-dark;
      }

      @else {
        color: $single-link-hover-color-dark;
      }
    }
  }
}
