.cc-window.cc-banner {
  .cc-btn {
    color: $global-font-color;

    &:hover,
    &:focus {
      background-color: #ccc;
    }

    [data-theme='dark'] & {
      color: $global-font-color;

      &:hover,
      &:focus {
        background-color: #fff;
      }
    }
  }
}
