// Color map of the code highlight
$code-highlight-color-map: (
  /* Error */ 'err': $code-error-color,
  /* Keyword */ 'k': #cf222e,
  /* KeywordConstant */ 'kc': #0550ae,
  /* KeywordDeclaration */ 'kd': #cf222e,
  /* KeywordNamespace */ 'kn': #cf222e,
  /* KeywordPseudo */ 'kp': #cf222e,
  /* KeywordReserved */ 'kr': #cf222e,
  /* KeywordType */ 'kt': #cf222e,
  /* Name */ 'n': $code-color,
  /* NameAttribute */ 'na': #0550ae,
  /* NameBuiltin */ 'nb': #0550ae,
  /* NameBuiltinPseudo */ 'bp': $code-color,
  /* NameClass */ 'nc': #8250df,
  /* NameConstant */ 'no': #cf222e,
  /* NameDecorator */ 'nd': #8250df,
  /* NameEntity */ 'ni': #0550ae,
  /* NameException */ 'ne': #0550ae,
  /* NameFunction */ 'nf': #0550ae,
  /* NameFunctionMagic */ 'fm': #0550ae,
  /* NameLabel */ 'nl': #0550ae,
  /* NameNamespace */ 'nn': #8250df,
  /* NameOther */ 'nx': #0550ae,
  /* NameProperty */ 'py': #0550ae,
  /* NameTag */ 'nt': #116329,
  /* NameVariable */ 'nv': #953800,
  /* NameVariableClass */ 'vc': #0550ae,
  /* NameVariableGlobal */ 'vg': #0550ae,
  /* NameVariableInstance */ 'vi': #0550ae,
  /* NameVariableMagic */ 'vm': #0550ae,
  /* Literal */ 'l': #0550ae,
  /* LiteralDate */ 'ld': #0a3069,
  /* LiteralString */ 's': #0a3069,
  /* LiteralStringAffix */ 'sa': #0a3069,
  /* LiteralStringBacktick */ 'sb': #0a3069,
  /* LiteralStringChar */ 'sc': #0a3069,
  /* LiteralStringDelimiter */ 'dl': #0a3069,
  /* LiteralStringDoc */ 'sd': #0a3069,
  /* LiteralStringDouble */ 's2': #0a3069,
  /* LiteralStringEscape */ 'se': #0a3069,
  /* LiteralStringHeredoc */ 'sh': #0a3069,
  /* LiteralStringInterpol */ 'si': #0a3069,
  /* LiteralStringOther */ 'sx': #0a3069,
  /* LiteralStringRegex */ 'sr': #0a3069,
  /* LiteralStringSingle */ 's1': #0a3069,
  /* LiteralStringSymbol */ 'ss': #0a3069,
  /* LiteralNumber */ 'm': #0550ae,
  /* LiteralNumberBin */ 'mb': #0550ae,
  /* LiteralNumberFloat */ 'mf': #0550ae,
  /* LiteralNumberHex */ 'mh': #0550ae,
  /* LiteralNumberInteger */ 'mi': #0550ae,
  /* LiteralNumberIntegerLong */ 'il': #0550ae,
  /* LiteralNumberOct */ 'mo': #0550ae,
  /* Operator */ 'o': #cf222e,
  /* OperatorWord */ 'ow': #cf222e,
  /* Punctuation */ 'p': $code-color,
  /* Comment */ 'c': #6e7781,
  /* CommentHashbang */ 'ch': #6e7781,
  /* CommentMultiline */ 'cm': #6e7781,
  /* CommentSingle */ 'c1': #6e7781,
  /* CommentSpecial */ 'cs': #6e7781,
  /* CommentPreproc */ 'cp': #0550ae,
  /* CommentPreprocFile */ 'cpf': #6e7781,
  /* Generic */ 'g': $code-color,
  /* GenericDeleted */ 'gd': #82071e,
  /* GenericEmph */ 'ge': $code-color,
  /* GenericError */ 'gr': $code-error-color,
  /* GenericHeading */ 'gh': $code-color,
  /* GenericInserted */ 'gi': #116329,
  /* GenericOutput */ 'go': $code-color,
  /* GenericPrompt */ 'gp': $code-color,
  /* GenericStrong */ 'gs': $code-color,
  /* GenericSubheading */ 'gu': $code-color,
  /* GenericTraceback */ 'gt': $code-error-color,
  /* GenericUnderline */ 'gl': $code-color,
  /* TextWhitespace */ 'w': $code-color,
  /* Other */ 'x': $code-color
) !default;
$code-highlight-color-map-dark: (
  /* Error */ 'err': $code-error-color,
  /* Keyword */ 'k': #f47067,
  /* KeywordConstant */ 'kc': #6cb6ff,
  /* KeywordDeclaration */ 'kd': #f47067,
  /* KeywordNamespace */ 'kn': #f47067,
  /* KeywordPseudo */ 'kp': #f47067,
  /* KeywordReserved */ 'kr': #f47067,
  /* KeywordType */ 'kt': #f47067,
  /* Name */ 'n': $code-color-dark,
  /* NameAttribute */ 'na': #6cb6ff,
  /* NameBuiltin */ 'nb': #dcbdfb,
  /* NameBuiltinPseudo */ 'bp': $code-color-dark,
  /* NameClass */ 'nc': #dcbdfb,
  /* NameConstant */ 'no': #6cb6ff,
  /* NameDecorator */ 'nd': #dcbdfb,
  /* NameEntity */ 'ni': #6cb6ff,
  /* NameException */ 'ne': #6cb6ff,
  /* NameFunction */ 'nf': #dcbdfb,
  /* NameFunctionMagic */ 'fm': #6cb6ff,
  /* NameLabel */ 'nl': #6cb6ff,
  /* NameNamespace */ 'nn': #f47067,
  /* NameOther */ 'nx': #dcbdfb,
  /* NameProperty */ 'py': #6cb6ff,
  /* NameTag */ 'nt': #8ddb8c,
  /* NameVariable */ 'nv': #f69d50,
  /* NameVariableClass */ 'vc': #6cb6ff,
  /* NameVariableGlobal */ 'vg': #6cb6ff,
  /* NameVariableInstance */ 'vi': #6cb6ff,
  /* NameVariableMagic */ 'vm': #6cb6ff,
  /* Literal */ 'l': #6cb6ff,
  /* LiteralDate */ 'ld': #96d0ff,
  /* LiteralString */ 's': #96d0ff,
  /* LiteralStringAffix */ 'sa': #96d0ff,
  /* LiteralStringBacktick */ 'sb': #96d0ff,
  /* LiteralStringChar */ 'sc': #96d0ff,
  /* LiteralStringDelimiter */ 'dl': #96d0ff,
  /* LiteralStringDoc */ 'sd': #96d0ff,
  /* LiteralStringDouble */ 's2': #96d0ff,
  /* LiteralStringEscape */ 'se': #96d0ff,
  /* LiteralStringHeredoc */ 'sh': #96d0ff,
  /* LiteralStringInterpol */ 'si': #96d0ff,
  /* LiteralStringOther */ 'sx': #96d0ff,
  /* LiteralStringRegex */ 'sr': #96d0ff,
  /* LiteralStringSingle */ 's1': #96d0ff,
  /* LiteralStringSymbol */ 'ss': #96d0ff,
  /* LiteralNumber */ 'm': #6cb6ff,
  /* LiteralNumberBin */ 'mb': #6cb6ff,
  /* LiteralNumberFloat */ 'mf': #6cb6ff,
  /* LiteralNumberHex */ 'mh': #6cb6ff,
  /* LiteralNumberInteger */ 'mi': #6cb6ff,
  /* LiteralNumberIntegerLong */ 'il': #6cb6ff,
  /* LiteralNumberOct */ 'mo': #6cb6ff,
  /* Operator */ 'o': #f47067,
  /* OperatorWord */ 'ow': #f47067,
  /* Punctuation */ 'p': $code-color-dark,
  /* Comment */ 'c': #768390,
  /* CommentHashbang */ 'ch': #768390,
  /* CommentMultiline */ 'cm': #768390,
  /* CommentSingle */ 'c1': #768390,
  /* CommentSpecial */ 'cs': #768390,
  /* CommentPreproc */ 'cp': #6cb6ff,
  /* CommentPreprocFile */ 'cpf': #768390,
  /* Generic */ 'g': $code-color-dark,
  /* GenericDeleted */ 'gd': #ffd8d3,
  /* GenericEmph */ 'ge': $code-color-dark,
  /* GenericError */ 'gr': $code-error-color,
  /* GenericHeading */ 'gh': $code-color-dark,
  /* GenericInserted */ 'gi': #b4f1b4,
  /* GenericOutput */ 'go': $code-color-dark,
  /* GenericPrompt */ 'gp': $code-color-dark,
  /* GenericStrong */ 'gs': $code-color-dark,
  /* GenericSubheading */ 'gu': $code-color-dark,
  /* GenericTraceback */ 'gt': $code-error-color,
  /* GenericUnderline */ 'gl': $code-color-dark,
  /* TextWhitespace */ 'w': $code-color-dark,
  /* Other */ 'x': $code-color-dark
) !default;