/* Modern browsers with `scrollbar-*` support (high priority) */
@supports (scrollbar-width: auto) {
  * {
    scrollbar-color: var(--#{$prefix}scrollbar-thumb-color) var(--#{$prefix}scrollbar-track-color);;
    scrollbar-width: var(--#{$prefix}scrollbar-width);
  }
}

/* Legacy browsers with `::-webkit-scrollbar-*` support */
::-webkit-scrollbar {
  height: var(--#{$prefix}scrollbar-width-legacy);
  width: var(--#{$prefix}scrollbar-width-legacy);
  overflow: visible;
}
::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}
::-webkit-scrollbar-corner {
  background-color: var(--#{$prefix}scrollbar-track-color);
}
::-webkit-scrollbar-thumb {
  background-color: var(--#{$prefix}scrollbar-thumb-color);
  &:hover {
    background-color: var(--#{$prefix}scrollbar-thumb-hover-color);
  }
}
::-webkit-scrollbar-track {
  background-color: var(--#{$prefix}scrollbar-track-color);
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-track {
  background-clip: padding-box;
  border: 3px solid transparent;
  border-radius: 100px;
}
