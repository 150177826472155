// ==============================
// Variables
// ==============================

// ========== Global ========== //
// Prefix for :root CSS variables.
$prefix: fi- !default;

// Font and Line Height
$global-font-family: system-ui, -apple-system, BlinkMacSystemFont, PingFang SC, Microsoft YaHei UI, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif !default;
$global-font-size: 16px !default;
$global-font-weight: 400 !default;
$global-line-height: 1.5rem !default;

// scroll-margin-top of content anchor
$global-scroll-margin-top: 0.5rem;

// Radius of the border
$global-border-radius: 5px !default;

// Color of the background
$global-background-color: #ffffff !default;
$global-background-color-dark: #292a2e !default;

// Color of the text
$global-font-color: #161209 !default;
$global-font-color-dark: #b1b1ba !default;

// Color of the secondary text
$global-font-secondary-color: #b1b1ba !default;
$global-font-secondary-color-dark: #909092 !default;

// Color of the link
$global-link-color: #161209 !default;
$global-link-color-dark: #b1b1ba !default;

// Color of the hover link
$global-link-hover-color: #2983bb !default; // 潮蓝
$global-link-hover-color-dark: #fff !default;

// Color of the border
// TODO refactor border colors
$global-border-color: #f0f0f0 !default;
$global-border-color-dark: #363636 !default;
// ========== Global ========== //

// ========== Scrollbar ========== //
// Color of the scrollbar
$scrollbar-color: #a6a6a6 !default;

// Color of the hover scrollbar
$scrollbar-hover-color: #7d7d7d !default;
// ========== Scrollbar ========== //

// ========== Selection ========== //
// Color of the selected text
$selection-color: rgba(53, 166, 247, 0.25) !default;
$selection-color-dark: rgba(50, 112, 194, 0.4) !default;
// ========== Selection ========== //

// ========== Header ========== //
// Height of the header
$header-height: 3.5rem !default;

// Color of the header background
$header-background-color: #f8f8f8 !default;
$header-background-color-dark: #252627 !default;

// Font style of the header title
$header-title-font-family: $global-font-family !default;
$header-title-font-size: 1.5rem !default;

// Color of the active menu item
$menu-active-color: #161209 !default;
$menu-active-color-dark: #fff !default;

// Border color of the menu item
$menu-border-color: darken($global-border-color, 5%) !default;
$menu-border-color-dark: lighten($global-border-color-dark, 5%) !default;

// Height of the submenu item of desktop header
$submenu-height: $header-height * 0.5 !default;

// Color of the search background
$search-background-color: #e9e9e9 !default;
$search-background-color-dark: #363636 !default;
// ========== Header ========== //

// ========== Tag Cloud ========== //
// Color range of tag cloud
$tag-cloud-start: $global-font-secondary-color!default;
$tag-cloud-end: $global-font-color!default;
$tag-cloud-start-dark: $global-font-secondary-color-dark!default;
$tag-cloud-end-dark: $global-font-color-dark!default;
// ========== Tag Cloud ========== //

// ========== Single Content ========== //
// Font size of the TOC
$toc-title-font-size: 1rem !default;
$toc-content-font-size: 0.875rem !default;

// Font size of the Collection List
$collection-title-font-size: 1rem !default;
$collection-list-font-size: 0.875rem !default;

// Color of the single link
$single-link-color: #2376b7 !default; // 花青
$single-link-color-dark: #1781b5 !default; // 釉蓝

// Color of the hover single link
$single-link-hover-color: #ea517f !default; // 莲瓣红
$single-link-hover-color-dark: #cc5595 !default; // 龙须红

// Color of the table background
$table-background-color: #fff !default;
$table-background-color-dark: #272c34 !default;

// Color of the table thead
$table-thead-color: #ededed !default;
$table-thead-color-dark: #20252b !default;

// Color of the blockquote
$blockquote-color: #697681 !default;
$blockquote-color-dark: #9ba3aa !default;

// Color of reward
$reward-color: tomato !default;
$reward-color-dark: darken($reward-color, 5%) !default;

// Width of reward image
$reward-img-width: 180px !default;
// ========== Single Content ========== //

// ========== Pagination ========== //
// Color of the link in pagination
$pagination-link-color: #bfbfbf !default;
$pagination-link-color-dark: #b1b1ba !default;

// Color of the hover link in pagination
$pagination-link-hover-color: #000 !default;
$pagination-link-hover-color-dark: #fff !default;
// ========== Pagination ========== //

// ========== Code ========== //
// Color of the code
$code-color: #24292f !default;
$code-color-dark: #adbac7 !default;

// Color of the code background
$code-background-color: #f6f8fa !default;
$code-background-color-dark: #2d333b !default;

$code-error-color: #dc3545 !default;

// Color of the hightlight code
$code-hightlight-color: #fff8c5 !default;
$code-hightlight-color-dark: rgba(174, 124, 20, 0.15) !default;

$code-info-color: #9c9c9c !default;
$code-info-color-dark: #b1b0b0 !default;

// Font size of the code
$code-font-size: 0.875rem !default;

// Font family of the code
$code-font-family: Source Code Pro, Menlo, Consolas, Monaco, monospace, $global-font-family !default;
// ========== Code ========== //

// ========== GitHub Corners ========== //
// Color of the GitHub Corners
$github-corner-color: white !default;
$github-corner-color-dark: black !default;

// Color of the GitHub Corners background
$github-corner-fill: $header-background-color-dark !default;
$github-corner-fill-dark: $header-background-color !default;
// ========== GitHub Corners ========== //

@import '_partials/_maps/admonition';
@import '_partials/_maps/alert';
@import '_partials/_maps/code-highlight';
@import '_partials/_maps/code-type';
@import '_partials/_maps/colors';
@import '_partials/_maps/utilities';
