.tag-cloud-tags {
  margin: 10px 0;

  @include link(true, true);

  a {
    display: inline-block;
    position: relative;
    margin: 5px 10px;
    @include overflow-wrap(break-word);
    @include transition(all ease-out 0.3s);

    &:active,
    &:focus,
    &:hover {
      @include transform(scale(1.2));

      sup {
        color: $global-font-color;
        @include transition(all ease-out 0.3s);

        [data-theme='dark'] & {
          color: $global-font-color-dark;
        }
      }
    }

    sup {
      color: $global-font-secondary-color;

      [data-theme='dark'] & {
        color: $global-font-secondary-color-dark;
      }
    }
  }
}

@for $grade from 0 through 10 {
  $tag-cloud-color: mix($tag-cloud-end, $tag-cloud-start, $grade * 10);
  $tag-cloud-color-dark: mix($tag-cloud-end-dark, $tag-cloud-start-dark, $grade * 10);

  .tag-cloud-#{$grade} {
    color: $tag-cloud-color!important;
    font-size: var(--tag-font);
    margin: 10px!important;

    &:active,
    &:focus,
    &:hover {
      color: $global-link-hover-color!important;

      [data-theme='dark'] & {
        color: $global-link-hover-color-dark;
      }

      sup {
        opacity: 1;
      }
    }

    [data-theme='dark'] & {
      color: $tag-cloud-color-dark!important;
    }

    sup {
      opacity: 0;
    }
  }
}
