// Utilities

// property order
$orders: (
  first: -1,
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  last: 6,
) !default;