.breadcrumb-container {
  padding-inline: 1rem;
  font-size: 0.75rem;
  background-color: $global-background-color;
  @include z-index(1);
  @extend .print-d-none;

  [data-theme='dark'] & {
    background-color: $global-background-color-dark;
  }

  &.sticky {
    position: sticky;
    top: $header-height;
  }

  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    margin: 0 auto;
    list-style: none;
    border-bottom: 1px inset $global-border-color;
    @extend %page-style;

    [data-theme='dark'] & {
      border-bottom-color: $global-border-color-dark;
    }

    .breadcrumb-item {
      display: flex;

      + .breadcrumb-item::before {
        content: attr(data-separator);
        display: inline-block;
        padding-inline: 0.5rem;
        color: $global-font-secondary-color;

        [data-theme='dark'] & {
          color: $global-font-secondary-color-dark;
        }
      }

      &.active {
        color: var(--#{$prefix}secondary);
      }
    }
  }
}
