.fixit-decryptor-container {
  font-family: $global-font-family;
  text-align: center;
  margin-block: var(--fixit-decryptor-margin-block);

  .fixit-decryptor-loading {
    vertical-align: middle;
  }

  .fixit-decryptor-input,
  .fixit-decryptor-btn,
  .fixit-encryptor-btn {
    display: none;
    box-sizing: border-box;
    outline: none;
    color: $global-font-color;
    border: 1px solid darken($global-border-color, 15%);
    border-radius: $global-border-radius;
    @include transition(all 0.1s ease-out);
    box-shadow: 0px 0px 5px 0px darken($global-border-color, 15%);

    &:active,
    &:focus,
    &:hover {
      color: darken($global-link-hover-color, 6%);
      border-color: $global-link-hover-color;
      box-shadow: 0px 0px 5px 0px lighten($global-link-hover-color, 20%);

      [data-theme='dark'] & {
        color: lighten($global-link-hover-color-dark, 6%);
        border-color: $global-link-hover-color-dark;
        box-shadow: 0px 0px 5px 0px darken($global-link-hover-color-dark, 20%);
      }
    }

    [data-theme='dark'] & {
      color: $global-font-color-dark;
      border-color: lighten($global-border-color-dark, 15%);
      box-shadow: 0px 0px 5px 0px lighten($global-border-color-dark, 15%);
    }
  }

  .fixit-decryptor-input {
    width: calc(clamp(50%, 450px, 100%) - 100px);
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: $global-background-color;

    [data-theme='dark'] & {
      background-color: $global-background-color-dark;
    }
  }

  .fixit-decryptor-btn,
  .fixit-encryptor-btn {
    cursor: pointer;
    @include transition(all 0.1s ease-out);
    padding: 0.8rem 1rem;

    background-color: $header-background-color;

    [data-theme='dark'] & {
      background-color: $header-background-color-dark;
    }
  }

  .fixit-encryptor-btn {
    display: none;
  }
}

fixit-encryptor {
  &.initialized > .fixit-decryptor-container {
    .fixit-decryptor-input,
    .fixit-decryptor-btn {
      display: inline-block;
    }

    .fixit-decryptor-loading {
      display: none;
    }
  }

  cipher-text {
    display: none !important;
  }
}

// fixit-encryptor for for the encrypted pages
article fixit-encryptor {
  .fixit-decryptor-container {
    --fixit-decryptor-margin-block: 2rem;
  }
  &.decrypted > .fixit-decryptor-container {
    .fixit-decryptor-loading,
    .fixit-decryptor-input,
    .fixit-decryptor-btn {
      display: none;
    }

    .fixit-encryptor-btn {
      display: inline-block;
    }
  }
}

// fixit-encryptor shortcodes
#content fixit-encryptor {
  .fixit-decryptor-container {
    --fixit-decryptor-margin-block: 1rem;
  }
  &.decrypted > .fixit-decryptor-container {
    display: none;
  }
}

.encrypted-hidden {
  display: none !important;
}
