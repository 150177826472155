@import '../_partials/_single/toc';
@import '../_partials/_single/fixit-decryptor';
@import '../_partials/_single/collections';

.single {
  .single-title {
    padding: 1rem 0 0.5rem;
    margin: 0;
    font-size: 1.6rem;
    font-weight: bold;
    line-height: 140%;

    .icon-pin {
      display: inline-block;
      width: max-content;
      transform: rotate(30deg);
      margin-right: 0.25em;
      color: var(--#{$prefix}danger);
    }

    .icon-repost {
      display: inline-block;
      width: max-content;
      margin-right: 0.25em;
      color: var(--#{$prefix}success);
    }
  }

  .single-subtitle {
    margin-block: 0 0.5rem;
    font-size: 1.2rem;
    font-weight: normal;
    line-height: 1.15;
  }

  .post-meta {
    font-size: 0.875rem;
    color: $global-font-secondary-color;

    .comment-visitors,
    .comment-count {
      @extend .print-d-none;
    }

    span {
      display: inline-block;
    }

    [data-theme='dark'] & {
      color: $global-font-secondary-color-dark;
    }

    @include link(false, true);

    .author {
      font-size: 1.05rem;

      img.avatar {
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 50%;
        vertical-align: text-bottom;
        border: 0.25px solid $global-border-color;
        box-sizing: border-box;
        object-position: center;
        @include object-fit(cover);

        [data-theme='dark'] & {
          border-color: $global-border-color-dark;
        }
      }
    }
  }

  .featured-image {
    margin: 1rem 0;

    img {
      display: block;
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      overflow: hidden;
    }
  }

  #content[data-end-flag]:not([data-password])::after {
    content: attr(data-end-flag);
    display: block;
    text-align: center;
    color: $global-font-secondary-color;
    font-size: 15px;
    padding: 5px;
  }

  hr {
    position: relative;
    height: 1em;
    margin-block: 1em;
    background: linear-gradient(to right, var(--#{$prefix}hr-background-color) 50%, transparent 50%);
    background-size: 10px 2px;
    background-position: center;
    background-repeat: repeat-x;
    border: none;

    &.awesome-hr {
      &::before {
        display: inline-block;
        font-weight: 600;
        font-family: 'Font Awesome 6 Free';
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        position: absolute;
        left: 5%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        color: var(--#{$prefix}hr-before-color);
        content: '\f0c4';
        font-size: 20px;
        line-height: 1;
        transition: left 1s ease-in-out, color 0.3s ease, border-color 0.3s ease;
      }
    
      &:hover::before {
        left: calc(95% - 20px);
      }
    }
  }

  .content {
    margin-block: 1rem;
    > h1,
    > h2 {
      font-size: 1.5em;
      padding-bottom: 0.3em;
      border-bottom: 1px solid $global-border-color;
    }

    > h3 {
      font-size: 1.25em;
    }

    > h4 {
      font-size: 1em;
    }

    > h5 {
      font-size: 0.875em;
    }

    > h6 {
      font-size: 0.85em;
    }

    > .heading-element {
      display: flex;
      font-weight: bold;
      line-height: 1.25;
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      [data-theme='dark'] & {
        font-weight: bolder;
      }

      code {
        padding: 0 .2em;
        font-size: inherit;
      }

      &:hover,
      &:target {
        > .heading-mark {
          opacity: 1;
        }
      }

      > .heading-mark {
        display: inline-flex;
        align-items: center;
        opacity: 0;
        padding-inline: 0.5rem;
        transition: all 0.2s ease-in-out;
        @include link(false, false);

        svg {
          fill: currentColor;
        }
      }
    }

    p {
      margin: 0.5rem 0;
    }

    b,
    strong {
      font-weight: bold;

      [data-theme='dark'] & {
        color: #ddd;
      }
    }

    @include link(false, false);

    a {
      white-space: normal;
      @include overflow-wrap(break-word);

      [data-theme='dark'] & b,
      [data-theme='dark'] & strong {
        color: $single-link-color-dark;
      }
    }

    [data-theme='dark'] a:hover b,
    [data-theme='dark'] a:hover strong {
      color: $single-link-hover-color-dark;
    }

    ul,
    ol,
    dl {
      margin-block: 0.5em;
    }

    ul {
      list-style-type: disc;

      // Task lists
      li[data-task] {
        list-style: none;
        margin-left: -1.5rem;
      }
    }

    // Task lists
    li[data-task] {
      color: var(--#{$prefix}task-color);
      .checkbox-icon {
        margin-right: 0.25em;
        color: var(--#{$prefix}checkbox-color);
      }
    }
    li[data-task='x'],
    li[data-task='-'] {
      --#{$prefix}task-color: var(--#{$prefix}secondary);
      text-decoration: line-through rgba(145, 145, 145, 0.6);
    }
    li[data-task='x'] {
      --#{$prefix}checkbox-color: var(--#{$prefix}primary);
    }
    li[data-task='-'] {
      opacity: 0.6;
    }
    li[data-task='/'] {
      --#{$prefix}task-color: var(--#{$prefix}success);
    }
    li[data-task='!'] {
      --#{$prefix}checkbox-color: var(--#{$prefix}danger);
    }
    li[data-task='?'] {
      --#{$prefix}checkbox-color: var(--#{$prefix}warning);
    }

    dl {
      dt {
        font-weight: bold;
      }
      dd {
        margin: 0.25em 0 1em;
      }
    }

    ruby {
      background: $code-background-color;

      rt {
        color: $global-font-secondary-color;
      }

      [data-theme='dark'] & {
        background: $code-background-color-dark;

        rt {
          color: $global-font-secondary-color-dark;
        }
      }
    }

    mark {
      padding: 0.14em 0.28em;
      background-color: var(--#{$prefix}mark-background-color, mark);
      color: var(--#{$prefix}mark-color, marktext);
      @include border-radius($global-border-radius);

      @each $color, $value in $theme-colors {
        &.mark-#{$color} {
          --#{$prefix}mark-color: #fff;
          --#{$prefix}mark-background-color: var(--#{$prefix}#{$color});
        }
      }
    }

    .table-wrapper {
      position: relative;
      overflow-x: auto;
      --#{$prefix}scrollbar-thumb-color: #{$table-background-color};

      [data-theme='dark'] & {
        --#{$prefix}scrollbar-thumb-color: #{$table-background-color-dark};
      }

      > table {
        width: 100%;
        max-width: 100%;
        margin: 0.625rem 0;
        border-spacing: 0;
        background: $table-background-color;
        border-collapse: collapse;

        [data-theme='dark'] & {
          background: $table-background-color-dark;
        }

        thead {
          background: $table-thead-color;

          [data-theme='dark'] & {
            background-color: $table-thead-color-dark;
          }
        }

        &:not([class]) tbody {
          & tr:nth-child(odd) {
            background: darken($table-background-color, 2.25%);

            [data-theme='dark'] & {
              background-color: lighten($table-background-color-dark, 2.75%);
            }
          }

          & tr:hover {
            background: darken($table-background-color, 4.5%);

            [data-theme='dark'] & {
              background-color: lighten($table-background-color-dark, 5.5%);
            }
          }
        }

        th,
        td {
          padding: 0.3rem 1rem;
          border: 1px solid darken($table-thead-color, 2%);

          [data-theme='dark'] & {
            border-color: darken($table-thead-color-dark, 2%);
          }
        }
      }
    }

    img {
      max-width: 100%;
      min-height: 1em;

      &:not([height]) {
        height: auto;
      }

      &[loading='lazy'][srcset]:not([data-lazyloaded]) {
        &:not(.suffix-invalid__small) {
          width: var(--width-small, var(--width));
          aspect-ratio: var(--aspect-ratio-small, var(--aspect-ratio));
        }

        &:not(.suffix-invalid) {
          @media (min-resolution: 1.01dppx) and (max-resolution: 1.5dppx) {
            width: calc(var(--width) / 1.5);
            aspect-ratio: var(--aspect-ratio);
          }
        }

        &:not(.suffix-invalid__large) {
          @media (min-resolution: 1.51dppx) {
            width: calc(var(--width-large, var(--width)) / 2);
            aspect-ratio: var(--aspect-ratio-large, var(--aspect-ratio));
          }
        }
      }

      &.version {
        height: 1.25em;
        vertical-align: text-bottom;
      }
    }
    svg {
      max-width: 100%;
    }

    figure {
      margin: 0.5rem;
      text-align: center;

      .image-caption:not(:empty) {
        min-width: 20%;
        max-width: 80%;
        display: inline-block;
        padding: 0.5rem;
        margin: 0 auto;
        font-size: 0.875rem;
        color: #969696;
      }

      img {
        display: block;
        margin: 0 auto;
        overflow: hidden;
      }
    }

    blockquote {
      display: block;
      border-left: 0.25em solid $global-border-color;
      color: $blockquote-color;
      padding: 0 1em;
      margin: 1rem 0;

      ul,
      ol {
        padding-left: 1.5em;
      }

      dl dd {
        margin-inline-start: 0.25em;
      }

      [data-theme='dark'] & {
        color: $blockquote-color-dark;
        border-left-color: $global-border-color-dark;
      }

      @include link(false, true);
    }

    sup[id^='fnref']:has(.footnote-ref) {
      padding: 2px 4px;
      background-color: rgba(238, 238, 238, 0.7);
      color: #555555;
      border-radius: 4px;
      cursor: pointer;

      [data-theme='dark'] & {
        background-color: rgba(56, 139, 253, 0.1);
        color: #58a6ff;
      }

      @include link(true, false);
    }

    .footnotes {
      font-size: 0.75rem;
      white-space: normal;
      color: darken($global-font-secondary-color, 5%);

      ol {
        margin-block: 1em;
        padding-left: 1rem;
      }
      
      hr {
        background-color: var(--#{$prefix}hr-background-color);
        height: 1px;
        margin-block: 1em;
      }

      code {
        font-size: 0.75rem;
      }

      a:not(.footnote-backref) {
        color: inherit;
        text-decoration: underline;
      }

      @include link(false, true);

      [data-theme='dark'] & {
        color: lighten($global-font-secondary-color-dark, 5%);
      }

      p {
        margin: 0.25rem 0;
      }
    }

    kbd {
      display: inline-block;
      padding: 0 0.25rem;
      background-color: $global-background-color;
      border: 1px solid $global-border-color;
      font-size: 0.8rem;
      font-family: $code-font-family;
      color: $code-color;
      @include border-radius($global-border-radius);
      @include box-shadow(inset 0 -1px 0 $global-border-color);

      [data-theme='dark'] & {
        background-color: $global-background-color-dark;
        border: 1px solid $global-border-color-dark;
        color: $code-color-dark;
        @include box-shadow(inset 0 -1px 0 $global-border-color-dark);
      }
    }

    // Katex styles
    .katex-display {
      max-width: 100%;
      overflow-x: auto;
      padding-block: 2px;
    }

    @import '../_partials/_single/alert';
    @import '../_partials/_single/code';
    @import '../_shortcodes';
  }

  @import '../_partials/_single/reward';
  @import '../_partials/_single/footer';
  @import '../_partials/_single/comment';
}

.lg-toolbar .lg-icon::after {
  color: #999;
}
