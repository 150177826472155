.bilibili {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
  margin: 1rem auto;
  text-align: center;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
